import { styled } from "@hoken/core/styles/stitches.config";

import { varsHoken } from "@hoken/design-system";
import { ChevronRightIcon } from "@radix-ui/react-icons";

export const EventContainer = styled("article", {
  backgroundColor: varsHoken.colors.gray850,
  backgroundPosition: "center",
  backgroundSize: "cover",
  color: "$white",
  cursor: "pointer",
  display: "flex",
  height: "7.5rem",
  justifyContent: "space-between",
  position: "relative",
  padding: "$4",
  minHeight: "225px",
  borderRadius: "1rem",
  flexWrap: "wrap",
  overflow: "hidden",

  ".event-container--logo": {
    width: "125px",
    height: "60px",
    position: "relative",
    textAlign: "end",
    display: "flex",
    justifyContent: "end",
    marginRight: "0px",
  },
  ".event-container--bottom": {
    fontFamily: "$helvetica",
    zIndex: 1,
    alignItems: "end",
  },
  ".event--image-bg": {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: "0",
    top: "0",
  },
  "@bp2": {
    ".event-container--bottom": {
      position: "absolute",
      bottom: "1rem",
    },
  },

  variants: {
    loading: {
      true: {
        loader: {},
      },
    },
    showAllDates: {
      true: {
        minHeight: "480px",
      },
    },
    page: {
      events: {
        height: "50vw",
        minHeight: "329px",
        "@bp2": {
          maxHeight: "480px",
          minHeight: "480px",
        },
      },
      home: {
        height: "50vw",
        minHeight: "329px",
        "@bp2": {
          maxHeight: "480px",
          minHeight: "480px",
        },
      },
    },
  },
  // Both rules below enable a dimming overlay to be set on the background image without impacting any child element
  "&::before": {
    content: "",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: "10px",
    zIndex: 1,
  },
  "& * ": {
    position: "relative",
  },
});

export const RightIconCenter = styled(ChevronRightIcon, {
  alignSelf: "center",
  position: "absolute",
});
