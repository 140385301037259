import { styled } from "@hoken/core/styles/stitches.config";

export const EventCardDatesCityDates = styled("a", {
  p: {
    transition: "color 0.3s ease",
  },
  "&:hover p": {
    color: "$green",
  },
});
