import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

export const SliderArrowSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 20 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M5.5 19.999C5.5 19.5771 5.64063 19.2021 5.92188 18.9209L13.8438 10.999L5.92188 3.03027C5.3125 2.46777 5.3125 1.4834 5.92188 0.920898C6.48438 0.311523 7.46875 0.311523 8.03125 0.920898L17.0312 9.9209C17.6406 10.4834 17.6406 11.4678 17.0313 12.0303L8.03125 21.0303C7.46875 21.6396 6.48438 21.6396 5.92188 21.0303C5.64063 20.749 5.5 20.374 5.5 19.999Z'
      fill='white'
    />
  </svg>
);

export const SliderArrow = styled(SliderArrowSVG, {
  height: "1.25rem",
  width: "1.25rem",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
});
