import { Link } from "@hoken/core";

import { SearchInputIcon } from "./search-input-icon";
import { SearchUIProps } from "./search.interfaces";
import { searchContainer } from "./search.vanilla.css";

import { Autolayout, Text } from "@hoken/design-system";

export const SearchAsButton = ({
  placeholder = "Search by event or city…",
  themeLight = false,
}: SearchUIProps) => {
  return (
    <Link href='/events/search' legacyBehavior>
      <a className={searchContainer({ themeLight: themeLight })}>
        <Autolayout paddingRight='xlarge' justify='center'>
          <Text type='H4Small' colors={themeLight ? "black" : "white"}>
            {placeholder}
          </Text>
        </Autolayout>
        <SearchInputIcon />
      </a>
    </Link>
  );
};
