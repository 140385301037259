import { styled } from "@hoken/core/styles/stitches.config";

export const FooterWrapper = styled("footer", {
  backgroundImage: `url("/images/bg/corner-black-bg.svg")`,
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
  zIndex: "9",
  backgroundSize: "cover",
  width: "100%",
  backgroundColor: "transparent",
  marginTop: "-1rem",
  position: "relative",
  minHeight: "126px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  padding: "1rem",
  ".footer--row": {
    width: "100%",
  },
  ".footer--hoken-logo": {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    svg: {
      margin: "$0",
    },
  },
  ".footer--contact-us": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: ".85rem",
    fontFamily: "libre franklin",
    marginTop: "1rem",
    flexDirection: "column",

    path: {
      fill: "$white",
    },
  },
  li: {
    marginBottom: "1rem",
    fontSize: "$hoken-core-font-15",
    "&:nth-child(1)": {
      fontWeight: "$bold",
    },
  },
  span: {
    color: "$green",
  },
  ".footer--company-slogan": {
    fontSize: "$hoken-core-font-20",
    lineHeight: "$hoken-core-line-height-24",
    fontFamily: "$helvetica",
    alignSelf: "baseline",
    marginTop: "$hoken-core-space-36",
    textAlign: "center",
    width: "100%",
  },
  ".footer--contact-and-rights": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "$8",
    flexDirection: "column",
    alignItems: "center",
    p: {
      fontSize: "$hoken-core-font-12",
    },
    ".icon svg": {
      height: "24px",
      width: "24px",
      marginLeft: "$0",
      display: "initial",
      marginRight: ".9rem",
    },
    ".first-svg svg": {
      marginRight: "1rem",
    },
    path: {
      fill: "$white",
    },
  },
});
