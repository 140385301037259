import { useEffect, useRef, useState } from "react";

/**
 * Hook to know when the component is on the top of the screen
 * and stick to it.
 */

export const useSticky = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);
  /**
   * We need to save the initial offset of the element for the cases where the browsers
   * update the viewport of the page with their URL bars or anything else.
   * */
  const [initialElementOffset, setInitialElementOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) {
        return;
      }
      if (!initialElementOffset) {
        setInitialElementOffset(ref.current.offsetTop);
      }
      // Safari has issues with getBoundingClientRect, so we use offsetTop instead
      const shouldBeSticky =
        initialElementOffset > 0 && initialElementOffset <= window.pageYOffset;

      setSticky(shouldBeSticky);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref, initialElementOffset]);

  return { ref, isSticky };
};
