import { forwardRef } from "react";

import { Box, Link, Text, formatDate } from "@hoken/core";

import { EventCardDatesProps } from "./event-card-dates.interfaces";
import { EventCardDatesCityDates } from "./event-card-dates.styles";

// TODO: Build a context to handle the event states
export const EventCardDates = forwardRef<HTMLDivElement, EventCardDatesProps>(
  ({ cities, eventName, showAllDates }, ref) => {
    const encodedName = encodeURIComponent(eventName.replace(/ /gi, "-").toLowerCase());

    return (
      <Box
        css={{
          zIndex: 2,
          background: "rgba(0, 0, 0, 0.80)",
          width: "100%",
          position: "absolute",
          left: "0",
          bottom: "0",
          height: !showAllDates ? 0 : "auto",
          padding: !showAllDates ? 0 : "1rem",
          minHeight: !showAllDates ? 0 : "75%",
          transition: !showAllDates
            ? "min-height, padding,  0.3s ease"
            : "min-height  0.3s ease",
          "@bp2": {
            minHeight: !showAllDates ? 0 : "390px",
          },
        }}
        className='event-card--dates'
        ref={ref}
      >
        <Box
          css={{
            paddingBottom: "1rem",
          }}
        >
          <Text typography='Paragraph_P2' color='White'>
            Choose your event’s date and location:
          </Text>
        </Box>
        <Box
          css={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {cities?.map((city) => {
            const encodedId = encodeURI(city.id);

            const eventLink = `/events/${encodedName}/${encodedId}`;

            return (
              <Box
                css={{
                  width: "33.33%",
                  marginBottom: "1rem",
                }}
                key={city.id}
              >
                <Link href={eventLink}>
                  <EventCardDatesCityDates>
                    <Text typography='Paragraph_P3_COMPENSATED' color='White'>
                      {city.city}, {city.state}
                    </Text>
                    <Text
                      typography='Paragraph_P3_COMPENSATED'
                      color='Brand_dark_primary'
                    >
                      {formatDate({ startDate: city.start_at, endDate: city.end_at })}
                    </Text>
                  </EventCardDatesCityDates>
                </Link>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  },
);

EventCardDates.displayName = "EventCardDates";
