import { useBreakpoint } from "@hoken/core";

import { SpotlightTagProps } from "./spotlight-tag.interfaces";
import { SpotlightTagWrapper } from "./spotlight-tag.vanilla.css";

import { Text } from "@hoken/design-system";

export const SpotlightTag = ({
  text,
  color,
  verticalPosition,
  horizontalPosition,
  position,
  showSpotlightTag,
  style,
}: SpotlightTagProps) => {
  const isDesktop = useBreakpoint("isDesktop");
  if (text !== "" && showSpotlightTag) {
    return (
      <div
        className={SpotlightTagWrapper({
          color,
          verticalPosition,
          horizontalPosition,
          position,
        })}
        style={style}
      >
        <Text type={isDesktop ? "P3" : "P4"} weight='bold'>
          {text}
        </Text>
      </div>
    );
  } else {
    return null;
  }
};
