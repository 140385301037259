// TODO: Refactor with Vanilla Extacts
import { useEffect, useState } from "react";

import { Box, HokenButton, SliderArrowSVG, useRouter, useSticky } from "@hoken/core";

import { EventCategory } from "../../../event.interfaces";

export const EventsCategories = ({
  categories,
  setSelectedCategory,
  selectedCategory,
}: {
  categories?: EventCategory[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | null>>;
  selectedCategory: string | null;
}) => {
  const { ref, isSticky } = useSticky();
  const router = useRouter();

  const scrollAmount = 80;
  const defaultFilter = "ALL";

  const filterBy = router.query.filterBy as string;

  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [isCategoriesScrolling, setIsCategoriesScrolling] = useState<boolean>(false);

  const handleRightArrowClick = () => {
    const scrollElement = ref.current;
    if (!scrollElement) {
      return;
    }

    const scrollWidth = scrollElement.scrollWidth;
    const scrollLeft = scrollElement.scrollLeft;
    const clientWidth = scrollElement.clientWidth;
    const scrollRight = scrollWidth - scrollLeft - clientWidth;
    const canScrollRight = scrollRight > 0;

    setShowLeftArrow(true);
    if (canScrollRight) {
      setIsCategoriesScrolling(true);
      scrollElement.scrollTo({
        left: scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
    scrollElement.addEventListener("scroll", handleCategoriesScrollEnd);
  };

  const updateSelectedCategory = (categoryId: string | null) => {
    if (!categoryId) {
      router.push({
        pathname: "/events",
      });
      return;
    }

    router.push({
      pathname: "/events",
      query: { filterBy: categoryId },
    });
  };

  const handleLeftArrowClick = () => {
    const scrollElement = ref.current;
    if (!scrollElement) {
      return;
    }

    const scrollLeft = scrollElement.scrollLeft;
    const canScrollLeft = scrollLeft > 0;
    const scrollEnds = scrollLeft - scrollAmount <= 0;

    if (canScrollLeft) {
      setIsCategoriesScrolling(true);
      scrollElement.scrollTo({
        left: scrollLeft - scrollAmount,
        behavior: "smooth",
      });
    }

    if (scrollEnds) {
      setShowLeftArrow(false);
    }
  };

  const handleCategoriesScrollEnd = () => {
    const scrollElement = ref.current;
    if (!scrollElement) {
      return;
    }
    setIsCategoriesScrolling(false);
    scrollElement.removeEventListener("scroll", handleCategoriesScrollEnd);
  };

  useEffect(() => {
    const scrollElement = ref.current;
    if (!scrollElement) {
      return;
    }
    const handleCategoriesManualScroll = () => {
      const scrollLeft = scrollElement.scrollLeft;
      const canScrollLeft = scrollLeft > 0;
      setShowLeftArrow(canScrollLeft);
    };

    scrollElement.addEventListener("scroll", handleCategoriesManualScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleCategoriesManualScroll);
    };
  }, [ref]);

  /**
   * Ensures that the values from next router are hydrated
   * Without this check valid values maybe undefined causing a FOIC
   */
  useEffect(() => {
    const isReady = router.isReady;
    const isDefaultFilter = !filterBy;
    if (isReady && isDefaultFilter) {
      setSelectedCategory(defaultFilter);
    }
  }, [router, filterBy, setSelectedCategory]);

  /**
   * Explicitly set the default or param filter after
   * hydration has taken place
   */
  useEffect(() => {
    if (filterBy === defaultFilter) {
      return setSelectedCategory(defaultFilter);
    }

    if (filterBy) {
      return setSelectedCategory(filterBy);
    }
  }, [filterBy, setSelectedCategory]);

  return (
    <Box
      ref={ref}
      css={{
        display: "flex",
        gap: "1rem",
        overflowX: "scroll",
        margin: "2rem",
        marginRight: "-1rem",
        marginLeft: "0",
        position: "sticky",
        background: "$black",
        minHeight: "77px",
        alignItems: "center",
        top: "$0",
        zIndex: "2",
        paddingRight: "1rem",
        svg: {
          width: "22px",
          height: "20px",
        },
        div: {
          minHeight: "50px",
          display: isSticky ? "flex" : "none",
          alignItems: "center",
          width: "40px",
          height: "77px",
          background: "linear-gradient(270deg, #000000 21.87%, rgba(0, 0, 0, 0) 100%)",
          position: "fixed",
          top: "0",
          margin: "$0 -9px",
        },
        ".categories-pills--left-arrow": {
          left: "5px",
          transform: "rotate(180deg)",
        },
        ".categories-pills--right-arrow": {
          right: "0",
        },
        "&::-webkit-scrollbar": {
          display: "none",
        },

        "@bp2": {
          position: "relative",
          width: "fit-content",
          overflowX: "scroll",
          margin: "auto",
        },
      }}
    >
      <>
        {showLeftArrow && (
          <div
            className='categories-pills--left-arrow'
            onTouchStart={() => handleLeftArrowClick()}
          >
            <SliderArrowSVG />
          </div>
        )}
        <div
          className='categories-pills--right-arrow'
          onTouchStart={() => handleRightArrowClick()}
        >
          <SliderArrowSVG />
        </div>
      </>
      <HokenButton
        css={{
          height: "45px",
          width: "fit-content",
          fontSize: "$hoken-core-font-15",
          "@bp2": {
            height: "50px",
          },
        }}
        disabled={isCategoriesScrolling}
        onClick={() => updateSelectedCategory(null)}
        type={selectedCategory === defaultFilter ? "PILL_ACTIVE" : "PILL"}
      >
        All
      </HokenButton>
      {/* TODO: Preload data cc: https://swr.vercel.app/docs/prefetching#top-level-page-data */}
      {categories?.map((category) => (
        <HokenButton
          css={{
            height: "45px",
            width: "fit-content",
            minWidth: "fit-content",
            fontSize: "$hoken-core-font-15",
            "@bp2": {
              height: "50px",
            },
          }}
          key={category.id}
          onClick={() => updateSelectedCategory(category.id)}
          type={
            selectedCategory && selectedCategory === category.id ? "PILL_ACTIVE" : "PILL"
          }
        >
          {category.name}
        </HokenButton>
      ))}
    </Box>
  );
};

export const EventsCategoriesAsLinks = ({
  categories,
  selectedCategory,
  themeLight,
}: {
  categories?: EventCategory[];
  selectedCategory: string | null;
  themeLight?: boolean;
}) => {
  const { ref, isSticky } = useSticky();
  const router = useRouter();

  const defaultFilter = "ALL";

  const updateSelectedCategory = (categoryId: string | null) => {
    if (!categoryId) {
      router.push({
        pathname: "/events",
      });
      return;
    }

    router.push({
      pathname: "/events",
      query: { filterBy: categoryId },
    });
  };

  useEffect(() => {
    const scrollElement = ref.current;
    if (!scrollElement) {
      return;
    }
  }, [ref]);

  /**
   * Explicitly set the default or param filter after
   * hydration has taken place
   */

  return (
    <Box
      ref={ref}
      css={{
        display: "flex",
        gap: "1rem",
        overflowX: "scroll",
        margin: "auto",
        marginLeft: "calc((100vw - 100%)/-2)",
        width: "100vw",
        paddingLeft: "1rem",
        minHeight: "77px",
        alignItems: "center",
        top: "$0",
        zIndex: "2",
        paddingRight: "1rem",
        svg: {
          width: "22px",
          height: "20px",
        },
        div: {
          minHeight: "50px",
          display: isSticky ? "flex" : "none",
          alignItems: "center",
          width: "40px",
          height: "77px",
          background: "linear-gradient(270deg, #000000 21.87%, rgba(0, 0, 0, 0) 100%)",
          position: "fixed",
          top: "0",
          margin: "$0 -9px",
        },
        ".categories-pills--left-arrow": {
          left: "5px",
          transform: "rotate(180deg)",
        },
        ".categories-pills--right-arrow": {
          right: "0",
        },
        "&::-webkit-scrollbar": {
          display: "none",
        },

        "@bp2": {
          position: "relative",
          width: "fit-content",
          overflowX: "scroll",
          margin: "auto",
        },
      }}
    >
      <HokenButton
        css={{
          height: "45px",
          width: "fit-content",
          fontSize: "$hoken-core-font-15",
          fontWeight: "normal",
          color: themeLight ? "$grey-80" : "$white",
          "@bp2": {
            height: "50px",
          },
        }}
        onClick={() => updateSelectedCategory(null)}
        type={selectedCategory === defaultFilter ? "PILL_ACTIVE" : "PILL"}
      >
        All
      </HokenButton>
      {/* TODO: Preload data cc: https://swr.vercel.app/docs/prefetching#top-level-page-data */}
      {categories?.map((category) => (
        <HokenButton
          css={{
            height: "45px",
            width: "fit-content",
            minWidth: "fit-content",
            fontSize: "$hoken-core-font-15",
            fontWeight: "normal",
            color: themeLight ? "$grey-80" : "$white",
            "@bp2": {
              height: "50px",
            },
          }}
          key={category.id}
          onClick={() => updateSelectedCategory(category.id)}
          type={
            selectedCategory && selectedCategory === category.id ? "PILL_ACTIVE" : "PILL"
          }
        >
          {category.name}
        </HokenButton>
      ))}
    </Box>
  );
};
