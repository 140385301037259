// Dependencies
import { useState } from "react";

import { Box, Image, Link, Text, pluralize } from "@hoken/core";

import { EventCardDates } from "./event-card-dates/event-card-dates";
import { EventCardFooter } from "./event-card-footer/event-card-footer";
import { EventCardTitle } from "./event-card-title/event-card-title";
// Interfaces
import { EventCardProps } from "./event-card.interface";
// Styles
import { EventContainer } from "./event-container.styles";

import { SpotlightTag } from "@hoken/design-system";

// TODO: Create context to handle event card state
export const EventCard = ({
  name,
  dateRange,
  city,
  hotel_room_count,
  href,
  page,
  state,
  eventImage,
  cities,
  strikethroughPercentage,
}: EventCardProps) => {
  const [showAllDates, setShowAllDates] = useState(false);
  const hasRoomsAvailable = hotel_room_count > 0;
  const listingsCopy = hasRoomsAvailable
    ? pluralize(hotel_room_count, "room").toUpperCase()
    : "Sold out";
  const numberOfCities = cities?.length;

  return (
    <Link href={href} legacyBehavior>
      <a>
        <EventContainer data-testid='event-card--container' page={page}>
          <SpotlightTag
            text={`Up to ${strikethroughPercentage}% off`}
            color='green'
            verticalPosition='top'
            horizontalPosition='right'
            showSpotlightTag={strikethroughPercentage}
            position='absolute'
          />
          {eventImage ? (
            <Box className='event--image-bg'>
              <Image
                src={eventImage}
                alt={"Event image"}
                fill
                style={{ objectFit: "cover" }}
              />
            </Box>
          ) : null}
          <Box
            css={{
              zIndex: 1,
              width: "100%",
              position: "relative",
            }}
          >
            <Text
              css={{
                marginBottom: "1.375rem",
              }}
              typography='Header_Eyebrow'
              color='White'
            >
              {listingsCopy}
            </Text>
            <EventCardTitle title={name} showAllDates={showAllDates} />
          </Box>
          <Box
            css={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",

              "@bp3": {
                width: "calc(100% - 2rem)",
              },
            }}
            className='event-container--bottom'
          >
            {!showAllDates ? (
              <EventCardFooter
                numberOfCities={numberOfCities}
                showAllDates={showAllDates}
                setShowAllDates={setShowAllDates}
                state={state}
                city={city}
                dateRange={dateRange}
                hasRoomsAvailable={hasRoomsAvailable}
              />
            ) : null}
          </Box>
          <EventCardDates showAllDates={showAllDates} eventName={name} cities={cities} />
        </EventContainer>
      </a>
    </Link>
  );
};
