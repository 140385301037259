import { Text, useBreakpoint } from "@hoken/core";

import { EventCardTitleProps } from "./event-card-title.interfaces";

export const EventCardTitle = ({ title, showAllDates }: EventCardTitleProps) => {
  const isDesktop = useBreakpoint("bp3");
  return (
    <>
      {isDesktop ? (
        <Text
          css={{
            marginTop: "0.3rem",
            textTransform: "uppercase",
            transition: "font-size 0.3s ease",

            variants: {
              loading: {
                true: {
                  loader: {
                    width: "15.625rem",
                    height: "1.25rem",
                  },
                },
              },
            },

            "@bp1": {
              truncate: "off",
            },
          }}
          typography={showAllDates ? "__UNIQUE__Header_16" : "__UNIQUE__Header_24"}
          color='White'
        >
          {title}
        </Text>
      ) : (
        <Text
          css={{
            marginTop: "1.3rem",
            textTransform: "uppercase",
            transition: "font-size 0.3s ease",

            variants: {
              loading: {
                true: {
                  loader: {
                    width: "15.625rem",
                    height: "1.25rem",
                  },
                },
              },
            },

            "@bp1": {
              truncate: "off",
            },
          }}
          typography={showAllDates ? "__UNIQUE__Header_16" : "__UNIQUE__Header_24"}
          color='White'
        >
          {title}
        </Text>
      )}
    </>
  );
};
