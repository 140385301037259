// Dependencies
import {
  Box,
  Email,
  Facebook,
  HokenLogo,
  Instagram,
  Link,
  Linkedin,
  StripeBadge,
  Tiktok,
  getCurrentYear,
} from "@hoken/core";

import { FooterWrapper } from "./footer-mobile-wrapper.styles";

import { Icons } from "@hoken/design-system";

export const FooterMobileWrapper = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <FooterWrapper className='text-white'>
      <div className='footer--row footer--hoken-logo'>
        <HokenLogo onClick={goToTop} />
      </div>
      <div className='footer--row footer--contact-us'>
        <div className='footer--row footer--company-links'>
          <div>
            <ul>
              <li>Company</li>
              <li>
                <Link href='/about-hoken' legacyBehavior>
                  <a>About Hoken</a>
                </Link>
              </li>
              <li>
                <a href='https://www.gohoken.com/blog'>Blog</a>
              </li>
              <li>
                <Link href='/terms-of-use' legacyBehavior>
                  <a>Terms of Use</a>
                </Link>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.linkedin.com/company/gohoken/jobs/'
                >
                  We&apos;re hiring
                </a>
              </li>
              <li>
                <Link
                  href='https://hoken.zendesk.com/hc/en-us/articles/10735214602395-How-can-I-partner-with-Hoken-'
                  legacyBehavior
                >
                  <a>
                    <span>Partner with us</span>
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <Box css={{ margin: "$4 $0", border: "solid 1px #363539" }} />
          <div>
            <ul>
              <li>Info</li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us/articles/25358342725275-Trust-Safety'
                >
                  Trust & Safety
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us'
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us'
                >
                  Support
                </a>
              </li>
              <li>
                <Link href='/privacy-statement' legacyBehavior>
                  <a>Privacy Policy</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer--company-slogan'>Perfect stays for epic events.</div>
        <div className='footer--contact-and-rights'>
          <div>
            <a href='mailto:support@gohoken.com' className='icon first-svg'>
              <Email />
            </a>
            <a
              href='https://twitter.com/gohoken'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Icons.XLogo />
            </a>
            <a
              href='https://www.instagram.com/gohoken/'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Instagram />
            </a>
            <a
              href='https://www.tiktok.com/@gohoken'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Tiktok />
            </a>
            <a
              href='https://www.facebook.com/profile.php?id=100086708942818'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Facebook
                css={{
                  marginTop: "-3px",
                }}
              />
            </a>
            <a
              href='https://www.linkedin.com/company/gohoken/'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Linkedin
                css={{
                  marginTop: "-2px",
                }}
              />
            </a>
          </div>
          <Box css={{ padding: "$8 0 $4 0", textAlign: "center" }}>
            <p>&copy; Hoken {getCurrentYear()}</p>
            <Box css={{ paddingBottom: "$8" }} />
            <StripeBadge column='reverse' linked={true} />
          </Box>
        </div>
      </div>
    </FooterWrapper>
  );
};
