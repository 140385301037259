import { varsHoken } from "@hoken/design-system/themes";
import { recipe } from "@vanilla-extract/recipes";

export const SpotlightTagWrapper = recipe({
  base: {
    padding: "0.25rem 0.5rem",
    borderRadius: "4px",
    width: "fit-content",
    zIndex: "1",
    textTransform: "uppercase",
  },

  variants: {
    position: {
      absolute: {
        position: "absolute",
      },
      relative: {
        position: "relative",
      },
    },
    color: {
      green: {
        backgroundColor: varsHoken.colors["green100"],
        color: varsHoken.colors["black"],
      },
      black: {
        backgroundColor: varsHoken.colors["black"],
        color: varsHoken.colors["white"],
      },
      gray: {
        backgroundColor: varsHoken.colors["gray500"],
        color: varsHoken.colors["black"],
      },
    },
    verticalPosition: {
      top: {
        top: ".75rem",
      },
      bottom: {
        bottom: ".75rem",
      },
      pushBackTop: {
        top: "-1rem",
      },
      topMobile: {
        top: ".5rem",
      },
    },
    horizontalPosition: {
      left: {
        left: "1rem",
      },
      right: {
        right: "1rem",
      },
      rightMobile: {
        right: ".5rem",
      },
    },
  },
});
