import { Box, HokenButton, Text, formatLocation } from "@hoken/core";

import { EventCardFooterProps } from "./event-card-footer.interfaces";

export const EventCardFooter = ({
  numberOfCities,
  state,
  city,
  dateRange,
  hasRoomsAvailable,
}: EventCardFooterProps) => {
  const hasCitiesAndRooms = numberOfCities >= 1 && hasRoomsAvailable;
  const hasNotCitiesButHasRooms = !numberOfCities && hasRoomsAvailable;

  const eventButtonCopy = (): string | React.ReactNode => {
    if (hasCitiesAndRooms) {
      return (
        <>
          {"See all"} <span>{numberOfCities} event dates</span>
        </>
      );
    }
    if (hasNotCitiesButHasRooms) {
      return "Go to event";
    }

    return "Subscribe for alerts";
  };

  const location = formatLocation({ city, state });

  return (
    <>
      <Box>
        {numberOfCities > 1 ? (
          <Text
            css={{
              marginBottom: "0.3rem",
              textTransform: "uppercase",
            }}
            typography='Paragraph_P3'
            color='White'
          >
            Upcoming:
          </Text>
        ) : null}
        <Text
          title={location}
          css={{
            width: "160px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "1rem",
            whiteSpace: "nowrap",
            variants: {
              loading: {
                true: {
                  loader: {
                    width: "200px",
                    height: "1rem",
                  },
                },
              },
            },
          }}
          typography='Header_Eyebrow'
          color='White'
        >
          {location}
        </Text>
        <Text
          css={{
            marginTop: "0.3rem",
          }}
          typography='Header_Eyebrow'
          color='White'
        >
          {dateRange}
        </Text>
      </Box>

      <Box>
        <HokenButton
          className={
            numberOfCities >= 1 && hasRoomsAvailable
              ? `event-card--footer-see-all-dates`
              : ""
          }
          css={{
            fontSize: ".75rem",
            alignItems: "end",
            fontFamily: "$franklin",
            fontWeight: "$bold",
            textAlign: "right",
            flexDirection: "column",
            gap: "0",
            justifyContent: "flex-end",
            span: {
              whiteSpace: "nowrap",
            },
          }}
          type='CTA_LINK_Dark'
        >
          {eventButtonCopy()}
        </HokenButton>
      </Box>
    </>
  );
};
