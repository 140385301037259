import { styled } from "@hoken/core/styles/stitches.config";

export const Layout = styled("main", {
  width: "100%",
  margin: "$0 auto",
  background: "$white",
  padding: "0 $4 5rem $4",
  variants: {
    stripped: {
      true: {
        padding: "$0 $4",
      },
      zero: {
        padding: "$0",
      },
    },
    cornerWhite: {
      true: {
        backgroundImage: `url("/images/bg/corner-white-bg.svg")`,
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        zIndex: "2",
        backgroundSize: "contain",
        width: "100%",
        backgroundColor: "transparent",
        marginTop: "-3rem",
        position: "relative",
        paddingBottom: "3rem",
        "@bp3": {
          backgroundImage: `url("/images/bg/bg-white-corner-desktop.svg")`,
          marginTop: "-6.5rem",
          backgroundSize: "100vw",
        },
      },
    },
    cornerBlack: {
      true: {
        backgroundImage: `url("/images/bg/corner-full-bg-black.svg")`,
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        zIndex: "1",
        backgroundSize: "101%",
        position: "relative",
        paddingBottom: "0",
        marginTop: "-2rem",
        backgroundColor: "transparent",
      },
      false: {
        padding: "$2 $4 5rem $4",
        background: "$black",
      },
    },
    blackBg: {
      true: {
        background: "$black",
      },
    },
    desktop: {
      true: {
        padding: "0",
        maxWidth: "100%",
        paddingBottom: "3rem",
      },
    },
    purpleBg: {
      true: {
        background: "$hoken-core-purple-gray-secondary",
      },
    },
    mainPurpleBg: {
      true: {
        background: "$hoken-core-purple-gray",
      },
    },
    fullHeight: {
      true: {
        minHeight: "100vh",
      },
    },
  },
});
